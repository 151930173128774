<template>
    <rs-row>
      <rs-card title="日期">
        <el-calendar v-model="time">
          <template
            slot="dateCell"
            slot-scope="{date, data}">
            <p :class="data.isSelected ? 'is-selected' : ''">
              {{data.day.split('-')[2]}}
            </p>
            <template v-for="(v,i) in value">
              <p :key="i" v-if="v.date == data.day">{{v.text}}</p>
            </template>
          </template>
        </el-calendar>
      </rs-card>
      <rs-card title="日志列表">
          <rs-table :data="log" :column="{id:'编号',name:'用户',form:'表',handle:'方法',newData:'新数据',oldData:'旧数据'}"></rs-table>
      </rs-card>
      <input v-model="oid" @keyup.enter="toCustom()" placeholder="输入oid" style="position: fixed;right: 0"/>
    </rs-row>
</template>

<script>
import request from '@/utility/request'
export default {
  data () {
    return {
      log: [],
      time: new Date(),
      value: [{
        date: '2022-09-01',
        text: '4'
      }],
      oid: null
    }
  },
  mounted () {
    this.getLog(this.time)
  },
  methods: {
    getLog (time) {
      var yeah = time.getFullYear()
      var month = time.getMonth() + 1
      var day = time.getDate()
      var date = yeah + '-' + month + '-' + day
      request.get('/log/list?time=' + date).then(data => {
        this.log = data.data
      })
    },
    toCustom () {
      request.get('/custom/getCustomId?oid=' + this.oid).then(
        data => {
          window.open('//sailing.sailingcn.top/custom/details?id=' + data.data.cid, '_blank')
        }
      )
    }
  },
  watch: {
    time (newStr, oldStr) {
      this.getLog(newStr)
    }
  }
}
</script>
